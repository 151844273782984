html {
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html * {
  padding: 0;
  margin: 0;
}

body {
  font-family: var(--font-primary) !important;
}

a,
a:link,
a:visited {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

button {
  background: none;
  border: none;
}

textarea {
  resize: none;
}

.padded-layout {
  padding: 1rem 1.5rem;
}

// * {
//   outline: 1px solid red;
// }

// !ONLY FOR TEST PURPOSE
// it breaks the modal opening
// * {
//   animation-duration: 0s !important;
//   animation: none !important;
// }
