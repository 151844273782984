.logo {
  min-height: 8rem;

  & img {
    display: block;
    margin: auto;
    padding-top: 3rem;
    // // margin-top: 2rem;
    // height: 3rem;
    // width: 8rem;
    // margin-left: auto;
    // margin-right: auto;
    width: 70%;
  }
}
