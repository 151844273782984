*, ::before, ::after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  --tw-blur: var(--tw-empty,/*!*/ /*!*/);
  --tw-brightness: var(--tw-empty,/*!*/ /*!*/);
  --tw-contrast: var(--tw-empty,/*!*/ /*!*/);
  --tw-grayscale: var(--tw-empty,/*!*/ /*!*/);
  --tw-hue-rotate: var(--tw-empty,/*!*/ /*!*/);
  --tw-invert: var(--tw-empty,/*!*/ /*!*/);
  --tw-saturate: var(--tw-empty,/*!*/ /*!*/);
  --tw-sepia: var(--tw-empty,/*!*/ /*!*/);
  --tw-drop-shadow: var(--tw-empty,/*!*/ /*!*/);
  --tw-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.visible {
  visibility: visible;
}
.relative {
  position: relative;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.ml-1 {
  margin-left: 0.25rem;
}
.mb-32 {
  margin-bottom: 8rem;
}
.mb-0 {
  margin-bottom: 0px;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mt-24 {
  margin-top: 6rem;
}
.block {
  display: block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.grid {
  display: grid;
}
.hidden {
  display: none;
}
.h-40 {
  height: 10rem;
}
.h-52 {
  height: 13rem;
}
.w-\[10rem\] {
  width: 10rem;
}
.w-20 {
  width: 5rem;
}
.w-36 {
  width: 9rem;
}
.w-40 {
  width: 10rem;
}
.w-full {
  width: 100%;
}
.w-max {
  width: -webkit-max-content;
  width: max-content;
}
.w-\[48rem\] {
  width: 48rem;
}
.w-1\/2 {
  width: 50%;
}
.w-60 {
  width: 15rem;
}
.max-w-7xl {
  max-width: 80rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.-rotate-90 {
  --tw-rotate: -90deg;
  -webkit-transform: var(--tw-transform);
          transform: var(--tw-transform);
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.animate-spin {
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-tr-none {
  border-top-right-radius: 0px;
}
.rounded-tl-none {
  border-top-left-radius: 0px;
}
.border {
  border-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-\[\#707070\] {
  --tw-border-opacity: 1;
  border-color: rgba(112, 112, 112, var(--tw-border-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.fill-current {
  fill: currentColor;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.text-center {
  text-align: center;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.font-semibold {
  font-weight: 600;
}
.tracking-\[28px\] {
  letter-spacing: 28px;
}
.tracking-\[10px\] {
  letter-spacing: 10px;
}
.tracking-\[1px\] {
  letter-spacing: 1px;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-opacity-80 {
  --tw-text-opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.filter {
  -webkit-filter: var(--tw-filter);
          filter: var(--tw-filter);
}
/* Section 1 Major chart CSS */
progress {
  display: block;
  width: 160px;
  border: 0;
  border-radius: 10px;
  background: #fff;
}
progress::-moz-progress-bar {
  background: #bcbec0;
}

/* webkit */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  progress {
    height: 10px;
  }
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  /* background: #bcbec0; */
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.redProgress::-webkit-progress-value {
  background: #f23bf2;
}
.blueProgress::-webkit-progress-value {
  background: #26c6da;
}
@media (min-width: 768px) {
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

:root{--font-primary: "Noto Sans JP";--border-radius: 4px}html{box-sizing:border-box;scroll-behavior:smooth;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}*,*::before,*::after{box-sizing:inherit}html *{padding:0;margin:0}body{font-family:"Noto Sans JP" !important;font-family:var(--font-primary) !important}a,a:link,a:visited{color:inherit;cursor:pointer;text-decoration:none}button{background:none;border:none}textarea{resize:none}.padded-layout{padding:1rem 1.5rem}.w-100{width:100%}.w-40{width:40%}.w-33{width:33%}.w-30{width:30%}.minh-100{min-height:100vh}.flex{display:flex}.flex-row{flex-direction:row}.flex-row-reverse{flex-direction:row-reverse}.flex-col{flex-direction:column}.flex-wrap{flex-wrap:wrap}.flex-nowrap{flex-wrap:nowrap}.justify-start{justify-content:flex-start}.justify-end{justify-content:flex-end}.justify-center{justify-content:center}.justify-between{justify-content:space-between}.justify-around{justify-content:space-around}.justify-evenly{justify-content:space-evenly}.items-start{align-items:flex-start}.items-end{align-items:flex-end}.items-center{align-items:center}.items-baseline{align-items:baseline}.items-stretch{align-items:stretch}.flex-column{flex-direction:column}.mt-1{margin-top:1rem}.mt-2{margin-top:2rem}.mb-1{margin-bottom:1rem}.mb-2{margin-bottom:2rem}.ml-1{margin-left:1rem}.ml-2{margin-left:2rem}.m-bottom-half{margin-bottom:.5rem}.m-bottom-2{margin-bottom:2rem}.m-bottom-4{margin-bottom:2rem}
.styles_logo__V0YqC{min-height:8rem}.styles_logo__V0YqC img{display:block;margin:auto;padding-top:3rem;width:70%}
.styles_bgGradient___uUK1 {
  background: linear-gradient(270deg, #ff0000c1 0%, #edaefc 50%, #3492ff 100%)
    0% 0% no-repeat padding-box;
}

.styles_verticalText__2Rax- {
  left: 0;
  margin-left: -67px;
  position: absolute;
  top: 430px;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}

.styles_verticalTextResult__3xBVh {
  left: unset;
  margin-left: unset;
  top: 50%;
}

