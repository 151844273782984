.w-100 {
  width: 100%;
}

.w-40 {
  width: 40%;
}

.w-33 {
  width: 33%;
}

.w-30 {
  width: 30%;
}

.minh-100 {
  min-height: 100vh;
}

/* LAYOUT */
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}

.flex-column {
  flex-direction: column;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2rem;
}
.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}

.m-bottom-half {
  margin-bottom: 0.5rem;
}

.m-bottom-2 {
  margin-bottom: 2rem;
}

.m-bottom-4 {
  margin-bottom: 2rem;
}
