@tailwind base;
@tailwind components;
@tailwind utilities;
/* Section 1 Major chart CSS */
progress {
  display: block;
  width: 160px;
  border: 0;
  border-radius: 10px;
  background: #fff;
}
progress::-moz-progress-bar {
  background: #bcbec0;
}

/* webkit */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  progress {
    height: 10px;
  }
}
progress::-webkit-progress-bar {
  background: transparent;
}
progress::-webkit-progress-value {
  /* background: #bcbec0; */
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}

.redProgress::-webkit-progress-value {
  background: #f23bf2;
}
.blueProgress::-webkit-progress-value {
  background: #26c6da;
}
