.bgGradient {
  background: linear-gradient(270deg, #ff0000c1 0%, #edaefc 50%, #3492ff 100%)
    0% 0% no-repeat padding-box;
}

.verticalText {
  left: 0;
  margin-left: -67px;
  position: absolute;
  top: 430px;
  transform-origin: 0 0;
  transform: rotate(270deg);
}

.verticalTextResult {
  left: unset;
  margin-left: unset;
  top: 50%;
}
